import React from 'react';
import { useLocation } from 'react-router-dom';
import MenuDrawer from './Drawer/MenuDrawer';
import AlertComponent from './Alert';
import LoadingIndicator from './LoadingIndicator';
import { Box } from '@mui/material';

const Layout: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const location = useLocation();
  const shouldRenderMenuDrawer = location.pathname !== '/login';

  return (
    <>
     <Box sx={{ display: 'flex' }}>

        {shouldRenderMenuDrawer && <MenuDrawer />}
        <AlertComponent />
        <LoadingIndicator />
        <Box
            component="main"
            sx={{
              flexGrow: 1,
              marginLeft: (theme) => theme.spacing(5),
              marginRight: (theme) => theme.spacing(5),
              transition: 'margin-left 0.3s',
            }}
          >
          {children}
        </Box>
      </Box>
    </>
  );
};

export default Layout;
