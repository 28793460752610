import React from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../redux/store/store';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, Typography, Box, TableContainer, Table, TableRow, TableBody, TableCell } from '@mui/material';
import { fetchLineChart, fetchNextQuoteToExpire, fetchNextToPayments, fetchPieChart } from '../../redux/actions/chartsActions';
import { PieChart } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts';
import { QuoteType } from '../../redux/types/quotes';
import { selectSelectedQuote } from '../../redux/reducers/quoteReducer';


const DashboardPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);
  const quotes = useSelector((state: RootState) => state.quotes.quotes);
  const dataPieChart = useSelector((state: RootState) => state.charts.result.pieChart);
  const dataLineChart = useSelector((state: RootState) => state.charts.result.lineChart);
  const dataNextExpire = useSelector((state: RootState) => state.charts.result.nextExpire);
  const dataNextPayments = useSelector((state: RootState) => state.charts.result.nextPayments);

  React.useEffect(() => {
    dispatch(fetchPieChart());
    dispatch(fetchLineChart());
    dispatch(fetchNextQuoteToExpire(5));
    dispatch(fetchNextToPayments(5));
  }, [dispatch]);

  const handleRowClick = (id: number) => {
    let quotesFilter: QuoteType[] = quotes.filter(quote => quote.id === id);
    if(quotesFilter.length === 1)
    {
      dispatch(selectSelectedQuote(quotesFilter[0]));
      navigate('/quote/details');
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flexGrow: 1, padding: '20px' }}>
        <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <Grid item xs={12} md={4}>
            <Card sx={{ minHeight:'450px' }}>
              <Box>
                <Typography variant="body1" sx={{ p: 2 }} gutterBottom>
                  Bienvenue ! Content de vous voir.
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', p: 2 }} gutterBottom>
                  {user?.firstName} {user?.lastName}
                </Typography>
                <Typography variant="body2" sx={{ p: 2 }} gutterBottom>
                  Répartition des montants des devis sur les 30 derniers jours
                </Typography>
                <PieChart
                    series={[
                      {
                        data: dataPieChart.map(item => ({
                          id: item.id,
                          value: item.value,
                          label: item.label
                        })),
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                      },
                    ]}
                    height={200}
                  />
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Card sx={{ minHeight:'450px' }}>
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold', p: 2 }} gutterBottom>
                  Vue d'ensemble
                </Typography>
                <Box textAlign="center">
                  <LineChart
                    series={[
                      { data: dataLineChart.map((item) => item.totalAmount), label: 'Montant des devis acceptés (en €)' },
                    ]}
                    xAxis={[{ scaleType: 'point', data: dataLineChart.map((item) => item.monthLabel) }]}
                    height={370}
                  />
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ minHeight:'450px' }}>
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold', p: 2 }} gutterBottom>
                  Les 5 prochains devis arrivant à expiration
                </Typography>
                <TableContainer sx={{ p:2, width: '95%' }}>
                  <Table >
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">Référence</TableCell>
                        <TableCell align="right">Client</TableCell>
                        <TableCell align="right">Montant</TableCell>
                        <TableCell align="right">Expiration</TableCell>
                      </TableRow>
                      {dataNextExpire.map((row) => (
                        <TableRow key={row.id}  style={{ cursor: 'default' }}>
                          <TableCell component="th" scope="row" onClick={() => handleRowClick(row.id)} style={{ cursor: 'pointer' }}>
                            <span style={{ textDecoration: 'underline' }}>
                              {row.refAppStudio}
                            </span>
                          </TableCell>
                          <TableCell align="right">{row.customerName}</TableCell>
                          <TableCell align="right">{row.totalAmount}€</TableCell>
                          <TableCell align="right">{new Date(row.expirationDate?? undefined).toLocaleDateString()}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
          <Card sx={{ minHeight:'450px' }}>
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold', p: 2 }} gutterBottom>
                  Les 5 prochains paiements
                </Typography>
                <TableContainer sx={{ p:2, width: '95%' }}>
                  <Table >
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">Référence</TableCell>
                        <TableCell align="right">Client</TableCell>
                        <TableCell align="right">Montant</TableCell>
                        <TableCell align="right">Envoyé</TableCell>
                      </TableRow>

                      {dataNextPayments.map((row) => (
                        <TableRow key={row.id}  style={{ cursor: 'default' }}>
                          <TableCell component="th" scope="row" onClick={() => handleRowClick(row.id)} style={{ cursor: 'pointer' }}>
                            <span style={{ textDecoration: 'underline'}}>
                              {row.refAppStudio}
                            </span>
                          </TableCell>
                          <TableCell align="right">{row.customerName}</TableCell>
                          <TableCell align="right">{row.totalAmount}€</TableCell>
                          <TableCell align="right">{new Date(row.updatedAt?? undefined).toLocaleDateString()}</TableCell>

                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DashboardPage;
