import React, { useEffect } from 'react';
import { RootState, useAppDispatch } from '../../redux/store/store';
import { useSelector } from 'react-redux';
import { ServiceType } from '../../redux/types/services';
import { Container } from 'react-bootstrap';
import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import { fetchServices } from '../../redux/actions/servicesActions';

const WikiPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const services = useSelector((state: RootState) => state.services.services as ServiceType[]);

    useEffect(() => {
        dispatch(fetchServices());
    }, [dispatch]);

    return (
        <Box sx={{ display: 'flex' }}> {/* Ajouter un conteneur flexible */}
            <Box sx={{ flexGrow: 1, padding: '20px' }}>
                <Container>
                    <Box textAlign="center" my={4}>
                        <Typography variant="h2" gutterBottom>
                            Façonnage en Imprimerie
                        </Typography>
                        <p>Le façonnage en imprimerie comprend diverses étapes essentielles pour transformer des feuilles imprimées en produits finis de haute qualité. Voici les principales étapes :</p>
                    </Box>
                    <Grid container spacing={2} justifyContent="center">
                        {services.map((step) => (
                            <Grid item m={2} xs={12} sm={6} md={4} key={step.id} flexGrow={1}>
                                <Card >
                                    <CardContent sx={{ minHeight: 150 }}>
                                        <Typography variant="h5" gutterBottom>
                                            {step.name}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {step.description}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small" href={step.link || ''} target="_blank">
                                            En savoir plus
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
};

export default WikiPage;
