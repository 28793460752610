import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Alert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import { RootState } from '../../redux/store/store';
import Box from '@mui/material/Box';
import { clearAlert } from '../../redux/reducers/alertReducer';

const AlertComponent: React.FC = () => {
  const alertMessages = useSelector((state: RootState) => state.alert.message);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(alertMessages).length > 0) {
      setIsModalOpen(true);
      const timeoutId = setTimeout(() => {
        setIsModalOpen(false);
        dispatch(clearAlert());
      }, 15000);

      return () => clearTimeout(timeoutId);
    } else {
      setIsModalOpen(false);
    }
  }, [alertMessages, dispatch]);

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          dispatch(clearAlert());
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {Object.entries(alertMessages).map(([severity, message]) => (
            <Alert key={severity} severity={severity as "error" | "warning" | "info" | "success"}>
              {message}
            </Alert>
          ))}
        </Box>
      </Modal>
    </>
  );
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  zIndex: 9999,
};

export default AlertComponent;
