import React, { FC, useState } from 'react';
import { TextField, Grid, Button, InputAdornment, Typography, CircularProgress } from '@mui/material';
import AddressForm from './AdressForm';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SearchIcon from '@mui/icons-material/Search';
import { setAlertWithDuration } from '../../redux/reducers/alertReducer';
import { useAppDispatch } from '../../redux/store/store';
import { addCustomer } from '../../redux/actions/custActions';
import { AdresseType } from '../../redux/types/customers';
import { AddCustomerError } from '../../redux/types/services';

interface AddClientFormProps {
  onClose: () => void;
}

const AddCustomerForm: FC<AddClientFormProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  // États pour les champs
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [siret, setSiret] = useState('');
  const [address, setAddress] = useState<AdresseType>({
    id: 0, row1: '', row2: '', row3: '', city: '', zipCode: '', country: '',
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  // Vérification des erreurs avant soumission
  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!name) newErrors.name = 'Le nom est obligatoire';
    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) newErrors.email = 'Veuillez entrer une adresse email valide';
    if (phoneNumber !== '' && !phoneNumber.match(/^[0-9]{10}$/)) newErrors.phoneNumber = 'Le numéro de téléphone doit comporter 10 chiffres';
    if (!siret.match(/^[0-9]{14}$/) && !siret.match(/^\d{3} \d{3} \d{3} \d{5}$/)) newErrors.siret = 'Le SIRET doit comporter 14 chiffres';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const fetchCompanyData = async () => {
    if (!siret.match(/^[0-9]{14}$/) && !siret.match(/^\d{3} \d{3} \d{3} \d{5}$/)) {
      dispatch(setAlertWithDuration('SIRET invalide.', 'error', 400));
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`https://recherche-entreprises.api.gouv.fr/search?q=${siret}&minimal=false`);
      const data = await response.json();

      if (data.results && data.results.length > 0) {
        const company = data.results[0];
        const companyName = company.siege.nom_commercial || company.nom_complet;
        const companyAddress = company.siege.adresse || '';
        
        const addressParts = companyAddress.split(' ');
        const addressWithoutPostalAndCity = addressParts.slice(0, addressParts.length - 2).join(' ');
  
        setName(companyName);

        setAddress({
          id: 0,
          row1: addressWithoutPostalAndCity,
          row2: '',
          row3: '',
          city: company.siege.libelle_commune || '',
          zipCode: company.siege.code_postal || '',
          country: 'France',
        });
      } else {
        dispatch(setAlertWithDuration('Aucune entreprise trouvée.', 'error', 404));
      }
    } catch (error) {
      dispatch(setAlertWithDuration('Erreur lors de la récupération des données.', 'error', 500));
    }
    setLoading(false);
  };

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!validateForm()) return;

    const customerData = {
      id: 0,
      name,
      email,
      phoneNumber,
      siret,
      address,
      customerServices: []
    };


    const result = await dispatch(addCustomer([ customerData ]));
    
    if (addCustomer.fulfilled.match(result)) {
      onClose();
    } else if (addCustomer.rejected.match(result)) {
      const errorPayload = result.payload as AddCustomerError;
      dispatch(setAlertWithDuration(`Erreur ${errorPayload?.code || 500}: ${errorPayload?.message || 'Une erreur est survenue.'}`, 'error', errorPayload?.code || 500));
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Nom*"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={!!errors.name}
            helperText={errors.name}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email*"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Téléphone*"
            fullWidth
            variant="outlined"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Siret*"
            fullWidth
            variant="outlined"
            value={siret}
            onChange={(e) => setSiret(e.target.value)}
            error={!!errors.siret}
            helperText={errors.siret}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BusinessCenterIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Button onClick={fetchCompanyData} disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : <SearchIcon />}
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Adresse</Typography>
          <AddressForm address={address} onChange={setAddress} />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Créer
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddCustomerForm;
