import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store/store';
import { CustomerType, AdresseType } from '../../redux/types/customers';
import { Typography, Paper, Box, TextField, Button, Grid, Tabs, Tab, Divider } from '@mui/material';
import { formatAddress } from '../../utils/utils';
import { useAppDispatch } from '../../redux/store/store';
import { useNavigate } from 'react-router-dom';
import { updateCustomer, deleteCustomer, initializeCustomService, updateCustomerService, deleteCustomerService } from '../../redux/actions/custActions';
import { ServiceType, ServicePriceType, CustomerServiceType, ServiceParameterType } from '../../redux/types/services';
import ServicesMenu from '../Services/ServicesMenu';
import { fetchServices } from '../../redux/actions/servicesActions';
import ServicesPrices from '../Services/ServicesPrices';
import ServicesParameters from '../Services/ServicesParameters';
import AddressForm from './AdressForm';
import ConfirmModal from '../common/modal/ConfirmModal';

const CustomerDetailsPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const selectCustomer = useSelector((state: RootState) => state.cust.selectCustomer);
  const services = useSelector((state: RootState) => state.services.services as ServiceType[]);
  const [customer, setCustomer] = useState<CustomerType | null>(null);
  const [editing, setEditing] = useState(false);
  const [editedCustomer, setEditedCustomer] = useState<CustomerType | null>(null);
  const [customerServiceSelected, setCustomerServiceSelected] = useState<CustomerServiceType | null>(null);
  const [selectedService, setSelectedService] = useState<ServiceType | null>(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isServiceInitialized, setIsServiceInitialized] = useState(false);
  const [customerAddress, setCustomerAddress] = useState({
    id: selectCustomer?.address?.id || '',
    row1: selectCustomer?.address?.row1 || '',
    row2: selectCustomer?.address?.row2 || '',
    row3: selectCustomer?.address?.row3 || '',
    city: selectCustomer?.address?.city || '',
    zipCode: selectCustomer?.address?.zipCode || '',
    country: selectCustomer?.address?.country || '',
  } as AdresseType);
  const userRole = useSelector((state: RootState) => state.auth.user?.role);
  const isAdmin = userRole === 'ADMIN';

  useEffect(() => {
    const foundCustomer = selectCustomer;
    setCustomer(foundCustomer || null);
    setEditedCustomer(foundCustomer || null);
  }, [selectCustomer]);

  useEffect(() => {
    dispatch(fetchServices());
  }, [dispatch]);

  const handleServiceSelect = (serviceId: number) => {
    const serviceSelected = services.find((service) => service.id === serviceId) || null;
    const customerServiceSelected = customer?.customerServices.find((service) => service.serviceId === serviceId) || null;
    setSelectedService(serviceSelected);
    setIsServiceInitialized(!!customerServiceSelected);
    setCustomerServiceSelected(customerServiceSelected);
  };

  const handleAddressChange = (newAddress: AdresseType) => {
    setCustomerAddress(newAddress);
    setEditedCustomer(prevState => ({
      ...prevState!,
      address: newAddress,
    }));
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSaveEdit = () => {
    if (editedCustomer) {
      dispatch(updateCustomer(editedCustomer));
      setEditing(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedCustomer(prevState => ({
      ...prevState!,
      [name]: value,
    }));
  };

  const handleDelete = () => {
    if (customer) {
      dispatch(deleteCustomer(customer.id));
      navigate(`/customers`);
    }
  };

  const initCustomerService = () => {
    if (customer && selectedService) {
      dispatch(initializeCustomService({ customerId: customer.id, serviceId: selectedService.id }));
    }
  };

  const deleteCustomerServiceClick = () => {
    if (customer && selectedService) {
      setShowModal(true);
    }
  }

  const handleConfirm = () => {
    setShowModal(false);
    if (customer && customerServiceSelected) {
      dispatch(deleteCustomerService({ customerId: customer.id, customerServiceId: customerServiceSelected.id }));
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    // Vérifiez si le service client a été initialisé
    if (customer && selectedService) {
      const customerService = customer.customerServices.find(service => service.serviceId === selectedService.id);
      if (customerService) {
        setIsServiceInitialized(true);
        setCustomerServiceSelected(customerService);
      } else {
        setIsServiceInitialized(false);
        setCustomerServiceSelected(null);
      }
    }
  }, [customer, selectedService]);

  const handleParameterChange = (
    paramIndex: number,
    nameValue: keyof ServiceParameterType,
    newValue: string
  ) => {
    if (selectedService && customer) {
      const updatedParams = [...customerServiceSelected!.parameters];
      updatedParams[paramIndex] = {
        ...updatedParams[paramIndex],
        [nameValue]: newValue
      };

      setCustomerServiceSelected(prevState => ({
        ...prevState!,
        parameters: updatedParams
      }));
    }
  };


  const handlePriceChange = (
    priceIndex: number,
    nameValue: keyof ServicePriceType,
    newValue: number
  ) => {
    if (selectedService && customer) {
      const updatedPrices = [...selectedService.prices];
      updatedPrices[priceIndex] = {
        ...updatedPrices[priceIndex],
        [nameValue]: newValue
      };

      setCustomerServiceSelected(prevState => {
        if (!prevState) {
          return prevState;
        }
        const updatedCustomerService: CustomerServiceType = {
          ...prevState,
          prices: updatedPrices
        };
        return updatedCustomerService;
      });
    }
  };

  

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleSave = () => {
    if (customerServiceSelected) {
      dispatch(updateCustomerService(customerServiceSelected));
    }
  };

  const { name, email, phoneNumber, siret, address } = editedCustomer || {};

  return (
    <Box sx={{ display: 'flex' }}> {/* Ajouter un conteneur flexible */}
      <Box sx={{ flexGrow: 1, padding: '20px' }}>
        <Box p={2}>
          <Box textAlign="center" my={4}>
            <Typography variant="h2" gutterBottom>
            {(editing ? 'Modifier le client' : 'Détails du client') + ' : ' + name}
            </Typography>
          </Box>
          <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
            <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
              <Tab label="Détails" />
              <Tab label="Services" />
            </Tabs>
            <Divider />
            {selectedTab === 0 && (
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="name"
                      label="Nom"
                      value={name || ''}
                      onChange={handleChange}
                      disabled={!editing}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="email"
                      label="Email"
                      value={email || ''}
                      onChange={handleChange}
                      disabled={!editing}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="phoneNumber"
                      label="Téléphone"
                      value={phoneNumber || ''}
                      onChange={handleChange}
                      disabled={!editing}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      name="siret"
                      label="SIRET"
                      value={siret || ''}
                      onChange={handleChange}
                      disabled={!editing}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {!editing ? (
                      <TextField
                          fullWidth
                          multiline
                          rows={3}
                          name="address"
                          label="Adresse"
                          value={address ? formatAddress(address) : ''}
                          onChange={handleChange}
                          disabled={!editing}
                        />
                        ) : (
                          <div>
                        <Typography>Adresse</Typography>
                        <AddressForm address={customerAddress} onChange={handleAddressChange} /></div>
                      )}
                  </Grid>
                </Grid>
                <Box mt={2} display="flex" justifyContent="space-between">
                  <Button onClick={editing ? handleSaveEdit : handleEdit} variant="contained" color="primary">
                    {editing ? 'Enregistrer' : 'Modifier'}
                  </Button>
                  {!editing && (
                    <Button onClick={handleDelete} variant="contained" color="secondary">
                      Supprimer
                    </Button>
                  )}
                </Box>
              </Box>
            )}
            {selectedTab === 1 && (
              <Box textAlign="center" mt={2}>
                <ServicesMenu onServiceSelect={handleServiceSelect} />
                <div style={{ margin: 10 }}>
                  <Divider />
                  <Typography variant="h4" gutterBottom m={2}>
                    {selectedService ? selectedService.name : 'Service non sélectionné'}
                  </Typography>
                  <Divider />
                </div>
                {!isServiceInitialized && (
                  <Box textAlign="center" my={2}>
                    <Typography gutterBottom m={2}>
                      Il n'existe pas de prix/paramètres personnalisés pour ce client.
                    </Typography>
                    <Button onClick={initCustomerService} variant="contained" color="secondary" disabled={!isAdmin}>
                      Initialiser
                    </Button>
                  </Box>
                )}
                {isServiceInitialized && customerServiceSelected && (
                  <>
                    <ServicesParameters
                      selectedParams={customerServiceSelected.parameters}
                      handleParameterChange={handleParameterChange}
                    />
                    <ServicesPrices
                      selectedPrices={customerServiceSelected.prices}
                      handlePriceChange={handlePriceChange}
                    />
                    <Button variant="contained" onClick={handleSave} color="primary" style={{ margin: '1rem' }} disabled={!isAdmin}>
                      Enregistrer
                    </Button>
                    <Button onClick={deleteCustomerServiceClick} variant="contained" color="secondary" style={{ margin: '1rem' }} disabled={!isAdmin}>
                      Supprimer
                    </Button>
                    <ConfirmModal open={showModal} handleClose={handleClose} handleConfirm={handleConfirm} />
                  </>
                )}
                
              </Box>
            )}
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerDetailsPage;
