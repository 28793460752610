import React, { FC, useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, SelectChangeEvent, Grid, TextField, Select, MenuItem, Checkbox, FormControl, InputLabel, IconButton } from '@mui/material';
import { RootState, useAppDispatch } from '../../../../redux/store/store';
import { useSelector } from 'react-redux';
import { ServiceType } from '../../../../redux/types/services';
import { VernisParametersType } from '../../../../redux/types/services/serviceParameters';
import { calculationRow } from '../../../../redux/actions/calculActions';
import { updateQuoteRow } from '../../../../redux/actions/quotesActions';
import CalculationResultDisplay from '../CalculationResultDisplay';
import { clearCalculationResult } from '../../../../redux/reducers/calculReducer';
import { QuoteRowType } from '../../../../redux/types/quotes';
import PopoverDetails from '../../PopoverDetails';
import CancelIcon from '@mui/icons-material/Cancel';

interface VernisProps{
  open: boolean;
  row: QuoteRowType;
  quoteId: number;
  handleClose: () => void;
}

const VernisModal: FC<VernisProps> = ({open, row, quoteId, handleClose }) => {
  const dispatch = useAppDispatch();
  const result = useSelector((state: RootState) => state.calcul.result);
  const services = useSelector((state: RootState) => state.services.services as ServiceType[]);
  const serviceSelected = services.find((service) => service.id === row.serviceId) || null;

  
  const [formValues, setFormValues] = useState<VernisParametersType>({
    type: 'vernis',
    typeVernis: row.typeVernis || '',
    isDoubleSided: row.isDoubleSided || false,
    description: row.description || '',
    sheetWidth: row.sheetWidth || 0,
    sheetLength: row.sheetLength || 0,
    sheetsNumber: row.sheetsNumber || 0,
    paperWeight: row.paperWeight || 0,
    pourcentageCouverture : row.pourcentageCouverture || 0,
    nbFile: row.nbFile || 0,
    price: row.price || 0,
    overloadPrice: row.overloadPrice || 0,
    rowId: row.id || 0,
    serviceId: row.serviceId,
    quoteId: quoteId,
  });

  useEffect(() => {
    if (open) {
      dispatch(clearCalculationResult());
    }
  }, [open, dispatch]);

  
  useEffect(() => {
    if (row.id !== null && quoteId !== null) {
      dispatch(calculationRow({ quoteId, rowId: row.id || 0, serviceParameters: formValues }));  
    }
  }, [formValues, row.id, quoteId, dispatch]);

  const handleChange = (field: string, value: string | number | boolean) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleFieldChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(field, event.target.value);
  };

  const handleSelectChange = (field: string) => (event: SelectChangeEvent<string>) => {
    handleChange(field, event.target.value);
  };

  const handleCheckboxChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(field, event.target.checked);
  };

  const handleSave = () => {
    if (row.id !== null && quoteId !== null) {
      formValues.price = result?.price || 0;
      dispatch(updateQuoteRow({ quoteId, rowId: row.id || 0, serviceParameters: formValues }));
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          position: 'absolute',
          width: '90%',
          maxHeight: '90vh',
          p: 4,
          overflowY: 'auto',
          overflowX: 'hidden',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 1
      }}>
        <Box textAlign="center" mb={2}>
          <Typography variant="h4" gutterBottom>
            Vernis
          </Typography>
        </Box>
        <Box sx={{ position: 'absolute', top: 16, right: 16, display: 'flex', alignItems: 'center' }}>
          <PopoverDetails details={result?.details || []} />
          <IconButton aria-label="cancel" onClick={handleClose}>
            <CancelIcon sx={{ color: '#d32f2f' }} />
          </IconButton>
        </Box>
        <Box sx={{ p: 2, border: '1px solid grey', borderRadius: 2 }}>
          <Typography variant="body1" sx={{ color: '#d32f2f' }} gutterBottom>
            Informations
          </Typography>
          {/* Section 1: Description et type */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Description"
                value={formValues.description}
                onChange={handleFieldChange('description')}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth >
              <InputLabel>Type de vernis</InputLabel>
              <Select
                label="Type de vernis"
                value={formValues.typeVernis}
                onChange={handleSelectChange('typeVernis')}
                fullWidth
                size="small"
              >
                {serviceSelected && serviceSelected.prices.map((price) => (
                  <MenuItem key={price.id} value={price.type}>
                    {price.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </Grid>
          </Grid>
          {/* Section 2: Nb feuilles / longeur / largeur / grammage */}
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Nombre de feuilles"
                value={formValues.sheetsNumber}
                onChange={handleFieldChange('sheetsNumber')}
                fullWidth
                size="small"
                inputProps={{ pattern: '[0-9]*' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Largeur"
                size="small"
                value={formValues.sheetWidth}
                onChange={handleFieldChange('sheetWidth')}
                fullWidth
                inputProps={{ pattern: '[0-9]*\\.?[0-9]+' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Longueur"
                size="small"
                value={formValues.sheetLength}
                onChange={handleFieldChange('sheetLength')}
                fullWidth
                inputProps={{ pattern: '[0-9]*\\.?[0-9]+' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Grammage"
                size="small"
                value={formValues.paperWeight}
                onChange={handleFieldChange('paperWeight')}
                fullWidth
                inputProps={{ pattern: '[0-9]*\\.?[0-9]+' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Pourcentage de couverture"
                size="small"
                value={formValues.pourcentageCouverture}
                onChange={handleFieldChange('pourcentageCouverture')}
                fullWidth
                inputProps={{ pattern: '[0-9]*\\.?[0-9]+' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Nombre de fichier"
                size="small"
                value={formValues.nbFile}
                onChange={handleFieldChange('nbFile')}
                fullWidth
                inputProps={{ pattern: '[0-9]*\\.?[0-9]+' }}
              />
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Box sx={{ mt: 1, p: 2, border: '1px solid grey', borderRadius: 2 }}>
              <Typography variant="body1" sx={{ color: '#d32f2f' }} gutterBottom>
                Options
              </Typography>
              <Checkbox
                checked={formValues.isDoubleSided}
                onChange={handleCheckboxChange('isDoubleSided')}
              />
              Recto/Verso
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Montant en €"
              size="small"
              value={formValues.overloadPrice}
              onChange={handleFieldChange('overloadPrice')}
              fullWidth
              inputProps={{ pattern: '[0-9]*\\.?[0-9]+' }}
            />
          </Grid>
        </Grid>
        {result && <CalculationResultDisplay result={result} />}
        <Box textAlign="center" mt={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ mr: 2 }}
            disabled={result ? !result.isValid : true}
          >
            Enregistrer
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Annuler
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default VernisModal;
