import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store/store';
import { RootState } from '../../redux/store/store';
import { fetchServices, updateService } from '../../redux/actions/servicesActions';
import ServicesMenu from '../Services/ServicesMenu';
import { ServiceType, ServicePriceType, ServiceParameterType } from '../../redux/types/services';
import Divider from '@mui/material/Divider';
import ServicesPrices from '../Services/ServicesPrices';
import ServicesParameters from '../Services/ServicesParameters';
import { Typography, Box, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';

const ServicesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const services = useSelector((state: RootState) => state.services.services as ServiceType[]);
  const [selectedService, setSelectedService] = useState<ServiceType | null>(null);

  useEffect(() => {
    dispatch(fetchServices());
  }, [dispatch]);

  useEffect(() => {
    if (services.length > 0 && !selectedService) {
      setSelectedService(services[0]);
    }
  }, [services, selectedService]);
  
  const handleServiceSelect = (serviceId: number) => {
    const selected = services.find((service) => service.id === serviceId) || null;
    if (selected) {
      setSelectedService(selected);
    }
  };

  const handleParameterChange = (
    paramIndex: number,
    nameValue: keyof ServiceParameterType,
    newValue: string
  ) => {
    if (selectedService) {
      const updatedParams = selectedService.parameters.map((param, index) => {
        if (index === paramIndex) {
          return {
            ...param,
            [nameValue]: newValue
          };
        }
        return param;
      });
      setSelectedService(prevState => ({
        ...prevState!,
        parameters: updatedParams
      }));
    }
  };
  
  const handlePriceChange = (
    priceIndex: number, 
    nameValue: keyof ServicePriceType,
    newValue: number
  ) => {
    if (selectedService) {
      const updatedPrices = [...selectedService.prices];
      updatedPrices[priceIndex] = {
        ...updatedPrices[priceIndex],
        [nameValue]: newValue
      };
      setSelectedService(prevState => ({
        ...prevState!,
        prices: updatedPrices
      }));      
    }
  };
  
  const handleSave = () => {
    if (selectedService) {
      dispatch(updateService(selectedService));
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flexGrow: 1, padding: '20px' }}>
        <Box textAlign="center" my={4}>
          <Typography variant="h2" gutterBottom>
            Liste des services
          </Typography>
        </Box>

        <Box textAlign="center" my={4}>
          <ServicesMenu onServiceSelect={handleServiceSelect} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width:'100%', justifyContent: 'center' }}>
            {selectedService && (
              <Box textAlign="center" my={2}>
                <div style={{ margin: 10 }}>
                  <Divider />
                  <Typography variant="h4" gutterBottom m={2}>
                    {selectedService.name}
                  </Typography>
                  <Divider />
                </div>
                <ServicesParameters
                  selectedParams={selectedService.parameters}
                  handleParameterChange={handleParameterChange}
                />
                <ServicesPrices
                  selectedPrices={selectedService.prices}
                  handlePriceChange={handlePriceChange}
                />
                <Accordion>
                  <AccordionSummary>
                    <Typography variant="h6">Commentaires</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {selectedService.comments.length > 0 ? (
                      <ul>
                        {selectedService.comments.map((comment) => (
                          <li key={comment.id}>{comment.comments}</li>
                        ))}
                      </ul>
                    ) : (
                      <Typography>Aucun commentaire disponible.</Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
                <Button variant="contained" onClick={handleSave} color="primary" style={{ marginTop: '1rem' }}>
                  Enregistrer
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ServicesPage;
