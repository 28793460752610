import React, { FC, useEffect, useState, useReducer } from 'react';
import { TextField, Grid, Button, InputAdornment, Typography, FormControl, InputLabel, MenuItem, Select, Box, Checkbox, FormControlLabel, CircularProgress } from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { RootState, useAppDispatch } from '../../redux/store/store';
import { addQuote } from '../../redux/actions/quotesActions';
import { useSelector } from 'react-redux';
import { fetchCustomers } from '../../redux/actions/custActions';
import { CustomerType } from '../../redux/types/customers';
import { ServiceType } from '../../redux/types/services';
import { fetchServices } from '../../redux/actions/servicesActions';
import { useNavigate } from 'react-router-dom';
import AddServiceForm from '../Services/AddServiceForm';
import { setAlertWithDuration } from '../../redux/reducers/alertReducer';

interface AddQuoteFormProps {
  onClose: () => void;
}

const initialState = {
  title: '',
  description: '',
  customer: undefined as CustomerType | undefined,
  customerReference: '',
  expirationDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
  selectedServices: [] as ServiceType[],
  showPriceService: false,
};

type Action =
  | { type: 'SET_FIELD'; field: string; value: any }
  | { type: 'SET_SERVICES'; services: ServiceType[] }
  | { type: 'SET_CUSTOMER'; customer: CustomerType | undefined };

const reducer = (state: typeof initialState, action: Action) => {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value };
    case 'SET_SERVICES':
      return { ...state, selectedServices: action.services };
    case 'SET_CUSTOMER':
      return { ...state, customer: action.customer };
    default:
      return state;
  }
};

const AddQuoteForm: FC<AddQuoteFormProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const customers = useSelector((state: RootState) => state.cust.customers as CustomerType[]);
  const services = useSelector((state: RootState) => state.services.services as ServiceType[]);

  const [loading, setLoading] = useState(true);
  const [state, dispatchLocal] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchCustomers());
      await dispatch(fetchServices());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Validation des champs
    if (!state.title || !state.customer) {
      dispatch(setAlertWithDuration('Veuillez remplir tous les champs obligatoires', 'error', '401'));
      return;
    }

    try {
      const selectedServiceIds = state.selectedServices.map(service => service.id);

      await dispatch(
        addQuote([{
          title: state.title,
          description: state.description,
          statusId: 1, // Valeur par défaut (Envoyé)
          customerId: state.customer.id,
          customerReference: state.customerReference,
          expirationDate: state.expirationDate,
          selectedServiceIds,
          showPriceService: state.showPriceService
        }])
      );
      navigate('/quote/details');
      onClose();
    } catch (error) {
      console.error('Une erreur s\'est produite lors de la création du devis :', error);
    }
  };

  const handleServiceToggle = (service: ServiceType) => {
    const updatedServices = state.selectedServices.includes(service)
      ? state.selectedServices.filter(selectedService => selectedService.id !== service.id)
      : [...state.selectedServices, service];

    dispatchLocal({ type: 'SET_SERVICES', services: updatedServices });
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
        {/* Section Général - Colonnes 1 & 2 */}
        <Grid item xs={12} md={6}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
            Général
          </Typography>
          <Box p={2}>
            <TextField
              label="Titre"
              fullWidth
              variant="outlined"
              value={state.title}
              onChange={(e) => dispatchLocal({ type: 'SET_FIELD', field: 'title', value: e.target.value })}
            />
            <FormControl fullWidth variant="outlined" style={{ marginTop: '16px' }}>
              <InputLabel id="customer-label">Client</InputLabel>
              <Select
                labelId="customer-label"
                value={state.customer ? state.customer.id : ''}
                onChange={(e) => {
                  const selectedCustomer = customers.find(customer => customer.id === e.target.value);
                  dispatchLocal({ type: 'SET_CUSTOMER', customer: selectedCustomer });
                }}
                label="Client"
              >
                {customers.map((customer) => (
                  <MenuItem key={customer.id} value={customer.id}>
                    {customer.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Description"
              fullWidth
              variant="outlined"
              value={state.description}
              onChange={(e) => dispatchLocal({ type: 'SET_FIELD', field: 'description', value: e.target.value })}
              multiline
              rows={4}
              style={{ marginTop: '16px' }}
              inputProps={{ maxLength: 255 }}
            />
            <TextField
              label="Référence client"
              fullWidth
              variant="outlined"
              value={state.customerReference}
              onChange={(e) => dispatchLocal({ type: 'SET_FIELD', field: 'customerReference', value: e.target.value })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BusinessCenterIcon />
                  </InputAdornment>
                ),
              }}
              style={{ marginTop: '16px' }}
            />
            <TextField
              label="Date d'expiration"
              type="date"
              fullWidth
              variant="outlined"
              value={state.expirationDate.toISOString().split('T')[0]}
              onChange={(e) => dispatchLocal({ type: 'SET_FIELD', field: 'expirationDate', value: new Date(e.target.value) })}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ marginTop: '16px' }}
            />
            <FormControlLabel label="Afficher le détails des prix" sx={{ mb: 2 }}
              control={
                <Checkbox
                  checked={state.showPriceService}
                  onChange={(e) => dispatchLocal({ type: 'SET_FIELD', field: 'showPriceService', value: e.target.checked })}
                />}
            />
          </Box>
        </Grid>
        {/* Section Choix des prestations */}
        <AddServiceForm
          services={services}
          selectedServices={state.selectedServices}
          handleServiceToggle={handleServiceToggle}
        />
        <Grid item xs={12} md={12} mb={2}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Créer le devis
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddQuoteForm;
