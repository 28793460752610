import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../redux/store/store';
import { fetchQuotes, fetchStatuesQuotes } from '../../redux/actions/quotesActions';
import { Box, Typography, Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { QuoteType } from '../../redux/types/quotes';
import { selectSelectedQuote } from '../../redux/reducers/quoteReducer';
import AddQuoteForm from '../Quote/AddQuoteForm';

const QuotesPage = () => {
  const dispatch = useAppDispatch();
  const quotes = useSelector((state: RootState) => state.quotes.quotes);
  const customers = useSelector((state: RootState) => state.cust.customers);
  const navigate = useNavigate();
  
  const [openCreateQuoteModal, setOpenCreateQuoteModal] = useState(false);

  useEffect(() => {
    dispatch(fetchQuotes());
    dispatch(fetchStatuesQuotes());
  }, [dispatch]);

  const getCustomerName = (customerId: number | undefined) => {
    if (customerId === undefined) return 'Inconnu';
    const customer = customers.find(c => c.id === customerId);
    return customer ? customer.name : 'Inconnu';
  };

  const handleTableRowClick = (quote: QuoteType) => {
    dispatch(selectSelectedQuote(quote));
    navigate('/quote/details');
  };

  const columns: MUIDataTableColumnDef[] = [
    { name: "id", label: "ID", options: { display: 'false', filter: false } },
    { name: "status", label: "Statut" },
    { name: "title", label: "Titre" },
    { name: "refAppStudio", label: "Référence" },
    { name: "customer", label: "Client" },
    { name: "createdDate", label: "Créé" },
    { name: "expirationDate", label: "Expire" },
    { name: "rows", label: "Ligne(s)" },
    { name: "totalAmount", label: "Montant" },
  ];

  const options: MUIDataTableOptions = {
    download: false,
    print: false,
    selectableRows: 'none',
    elevation: 0,
    sortOrder: { name: 'id', direction: 'desc' },
    onRowClick: (rowData, rowMeta) => {
      const quote = quotes[rowMeta.dataIndex];
      handleTableRowClick(quote);
    },
    textLabels: {
      body: { noMatch: "Aucun devis disponible" },
      pagination: { rowsPerPage: "Lignes par page:" },
      filter: { all: "Tous", title: "Mes filtres", reset: "Réinitialiser" },
      toolbar: { viewColumns: "Affichage", filterTable: "Filtrer", search: "Rechercher" },
      viewColumns: { title: "" }
    },
  };

  const data = quotes.map(quote => ({
    ...quote,
    customer: getCustomerName(quote.customerId),
    createdDate: new Date(quote.createdAt ?? new Date()).toLocaleDateString(),
    expirationDate: new Date(quote.expirationDate ?? new Date()).toLocaleDateString(),
    rows: quote.rows ? quote.rows.length : 0,
    status: quote.status.name,
    totalAmount: `${quote.totalAmount} €`,
  }));

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flexGrow: 1, padding: '20px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 2 }}>
          <Typography variant="h3" gutterBottom>
            Devis
          </Typography>
          <Button variant="contained" color="primary" onClick={() => setOpenCreateQuoteModal(true)}>
            Nouveau devis
          </Button>
        </Box>
        
        <Box sx={{ margin: '0 auto' }}>
          <MUIDataTable
            title={""}
            data={data}
            columns={columns}
            options={options}
          />
        </Box>
      </Box>

      {/* Modal de création de devis */}
      <Dialog open={openCreateQuoteModal} onClose={() => setOpenCreateQuoteModal(false)}>
        <DialogTitle>Créer un nouveau devis</DialogTitle>
        <DialogContent>
          <AddQuoteForm
            onClose={() => setOpenCreateQuoteModal(false)}  // Ferme le modal après soumission
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default QuotesPage;
