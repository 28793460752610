import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AlertState {
  message: { [key: string]: string };
  code: string
}

const initialState: AlertState = {
  message: {},
  code: '',
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<{ key: string; value: string, code: string }>) => {
      const { key, value } = action.payload;
      state.message[key] = value;
    },
    clearAlert: (state) => {
      state.message = {};
    },
  },
});

export const { setAlert, clearAlert } = alertSlice.actions;

export const setAlertWithDuration = (message: string, severity: string, code: string | number) => (dispatch: any) => {
  dispatch(setAlert({ key: severity, value: message, code: String(code) }));
  setTimeout(() => {
    dispatch(clearAlert());
  }, 10000);
};

export default alertSlice.reducer;
