import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store/store';
import { fetchCustomers } from '../../redux/actions/custActions';
import { RootState } from '../../redux/store/store';
import { Typography, Box, Button } from '@mui/material';
import CustomModal from '../common/modal/CustomModal';
import AddCustomerForm from '../Customer/AddCustomerForm';
import { formatAddress } from '../../utils/utils';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import { useNavigate } from 'react-router-dom';
import { selectCustomer } from '../../redux/actions/custActions';

const CustomersPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const clients = useSelector((state: RootState) => state.cust.customers);
  const [openCreateClientModal, setOpenCreateClientModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchCustomers());
    };
    fetchData();
  }, [dispatch]);

  const columns: MUIDataTableColumnDef[] = useMemo(() => [
    { name: "id", label: "ID", options: { display: 'false', filter: false } },
    { name: "name", label: "Nom" },
    { name: "email", label: "Email" },
    { name: "siret", label: "Siret" },
    { name: "phoneNumber", label: "Téléphone" },
    { name: "address", label: "Adresse" }
  ], []);

  const options: MUIDataTableOptions = useMemo(() => ({
    download: false,
    print: false,
    selectableRows: 'none',
    elevation: 0,
    sortOrder: { name: 'name', direction: 'asc' },
    onRowClick: (rowData: string[]) => {
      const customerId = parseInt(rowData[0], 10);
      const customer = clients.find(client => client.id === customerId);
      if (customer) {
        dispatch(selectCustomer(customer));
        navigate(`/customer/details`, { replace: true });
      }
    },
    textLabels: {
      body: { noMatch: "Aucun client disponible" },
      pagination: { rowsPerPage: "Lignes par page:" },
      filter: { all: "Tous", title: "Mes filtres", reset: "Réinitialiser" },
      toolbar: { viewColumns: "Affichage", filterTable: "Filtrer", search: "Rechercher" },
      viewColumns: { title: "" }
    }
  }), [clients, dispatch, navigate]);

  const data = clients.map(client => ({
    ...client,
    address: formatAddress(client.address)
  }));

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flexGrow: 1, padding: '20px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 2 }}>
          <Typography variant="h3" gutterBottom>
            Clients
          </Typography>
          <Button variant="contained" color="primary" onClick={() => setOpenCreateClientModal(true)}>
            Nouveau client
          </Button>
        </Box>
        <Box sx={{ margin: '0 auto' }}>
          <MUIDataTable
            title=""
            data={data}
            columns={columns}
            options={options}
          />
        </Box>
        <CustomModal
          open={openCreateClientModal}
          title="Créer un client"
          onClose={() => setOpenCreateClientModal(false)}
        >
          <AddCustomerForm onClose={() => setOpenCreateClientModal(false)} />
        </CustomModal>
      </Box>
    </Box>
  );
};

export default CustomersPage;
