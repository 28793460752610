import React from 'react';
import { Typography, Grid, FormControlLabel, Checkbox, List, ListItem, ListItemButton } from '@mui/material';
import { ServiceType } from '../../redux/types/services';

interface AddServiceFormProps {
  services: ServiceType[];
  selectedServices: ServiceType[];
  handleServiceToggle: (service: ServiceType) => void;
  handleCardClick?: (service: ServiceType) => void;
}

const AddServiceForm: React.FC<AddServiceFormProps> = ({ services, selectedServices, handleServiceToggle, handleCardClick }) => {

  return (
    <Grid item xs={12} md={6}>
      <Typography variant="body1" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
        Choix des prestations
      </Typography>

      <List sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
        {services.map((service) => (
          <ListItem disablePadding key={service.id} sx={{ width: '100%' }}>
            <ListItemButton
              selected={selectedServices.some((selectedService) => selectedService.id === service.id)}
              onClick={() => handleServiceToggle(service)}
              sx={{
                '&:hover': { backgroundColor: '#ffebee' },
                '&.Mui-selected': { backgroundColor: '#ffcdd2' },
                borderRadius: 1,
                padding: 1.5,
                width: '100%',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedServices.some((selectedService) => selectedService.id === service.id)}
                    onChange={() => handleServiceToggle(service)}
                    sx={{ color: '#d32f2f', '&.Mui-checked': { color: '#b71c1c' } }}
                  />
                }
                label={<Typography variant="body2">{service.name}</Typography>}
                onClick={(e) => e.stopPropagation()}
                sx={{ flexGrow: 1 }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Grid>
  );
};

export default AddServiceForm;
