import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './components/pages/LoginPage';
import QuotesPage from './components/pages/QuotesPage';
import CustomersPage from './components/pages/CustomersPage';
import ServicesPage from './components/pages/ServicesPage';
import CustomerDetailsPage from './components/Customer/CustomerDetailsPage';
import PrivateRoute from './components/PrivateRoute';
import DashboardPage from './components/pages/DashboardPage';
import { store, persistor } from './redux/store/store';
import theme from './shared/theme/theme';
import { ThemeProvider } from '@mui/material/styles';
import WikiPage from './components/pages/WikiPage';
import QuoteDetailsPage from './components/Quote/QuoteDetailsPage';
import AboutUsPage from './components/pages/AboutUsPage';
import ResetPasswordPage from './components/auth/ResetPasswordPage';
import ActivateAccountPage from './components/auth/ActivateAccountPage';
import AddCustomerPage from './components/Customer/AddCustomerPage';
import ParametersPage from './components/pages/ParametersPage';
import LoadingIndicator from './components/common/LoadingIndicator';
import Layout from './components/common/Layout';

function App() {

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={<LoadingIndicator />} persistor={persistor}>
          <Router>
            <Layout>
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/resetPassword" element={<ResetPasswordPage />} />
                <Route path="/activateAccount" element={<ActivateAccountPage />} />
                <Route path="/parameters" element={<ParametersPage />} />
                <Route path="/quote" element={<PrivateRoute element={QuotesPage} />} />
                <Route path="/quote/details" element={<PrivateRoute element={QuoteDetailsPage} />} />
                <Route path="/customers" element={<PrivateRoute element={CustomersPage} />} />
                <Route path="/customers/create" element={<PrivateRoute element={AddCustomerPage} />} />
                <Route path="/customer/details" element={<PrivateRoute element={CustomerDetailsPage} />} />
                <Route path="/services" element={<PrivateRoute element={ServicesPage} />} />
                <Route path="/details" element={<PrivateRoute element={WikiPage} />} />
                <Route path="/aboutus" element={<PrivateRoute element={AboutUsPage} />} />
                <Route path="/" element={<PrivateRoute element={DashboardPage} /> } />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Layout>
          </Router>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
